<template>
    <div class="supplier">
        <div class="filter-panel">
            <input
                style="width:300px"
                type="text"
                placeholder="搜索公司名称/对接人/对接人手机号/备注"
                v-model="searchTerm.detail"
                class="cs-input"
            />
            <CSSelect style="margin-right:30px;">
                <select v-model="searchTerm.state">
                    <option selected value="">
                        供应商状态不限
                    </option>
                    <option
                        v-for="supplier in supplierStateList"
                        :value="supplier.id"
                        :key="supplier.id"
                    >{{supplier.name}}</option>
                </select>
            </CSSelect>
            <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="getSupplier()"
            >查询</button>
        </div>
        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:header>
                    <div class="table-header-panel text-right">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm sticky-right"
                            @click="addSuppiler()"
                        >
                            <svg
                                class="icon"
                                aria-hidden="true"
                            >
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            供应商
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>添加时间</th>
                        <th>公司名称</th>
                        <th>营业执照</th>
                        <th>供应商状态</th>
                        <th>物品</th>
                        <th>对接人</th>
                        <th>对接人手机号</th>
                        <th>附件</th>
                        <th>备注</th>
                        <th>创建人</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr
                        v-for="(supplier,supplierIndex) in supplierInfo"
                        :key="supplierIndex"
                        :data-index="supplier.id"
                    >
                        <td class="date-td">{{supplier.createTime}}</td>
                        <td>
                            <span v-if="supplier.state === 3">-</span>
                            <span
                                v-else
                                class="allow-click"
                                @click="companyInterface(supplier)"
                            >
                                {{supplier.companyName}}
                            </span>
                        </td>
                        <td>
                            <span v-if="supplier.state === 3">-</span>
                            <span
                                v-else
                                class="allow-click"
                                @click="checkLicense(supplier)"
                            >
                                查看
                            </span>
                        </td>
                        <td>{{supplier.stateCode}}</td>
                        <td>
                            <span v-if="supplier.state === 3">-</span>
                            <span
                                v-else
                                class="allow-click"
                                @click="itemQuantityInterface(supplier,tabBarType)"
                            >
                                {{supplier.goodsCount}}
                            </span>
                        </td>
                        <td>{{supplier.dockingPeople}}</td>
                        <td>{{supplier.dockingPeoplePhone}}</td>
                        <td>
                            <span
                                v-if="supplier.state === 1 && supplier.annex !== '[]' && supplier.annex"
                                class="allow-click"
                                @click="fileInterface(supplier,tabBarType = 4)"
                            >查看</span>
                            <span v-else>-</span>
                        </td>
                        <td>
                            <span
                                v-if="supplier.state === 1 && supplier.comment"
                                class="allow-click"
                                @click="noteInterface(supplier)"
                            >查看</span>
                            <span v-else>-</span>
                        </td>
                        <td>{{supplier.createUserName}}【{{supplier.jobTitle}}】</td>
                        <td>
                            <span v-if="supplier.state === 3">-</span>
                            <div v-else>
                                <div v-show="supplier.state === 1" class="btn-group">
                                    <button
                                        class="btn btn-primary dropdown-toggle"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >操作</button>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <a @click="isForrbiden('forrbiden',supplier)" style="width:100%">禁用</a>
                                        </li>
                                    </ul>
                                </div>
                                <div v-show="supplier.state !== 1" class="btn-group">
                                    <button
                                        class="btn btn-primary dropdown-toggle"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >禁用</button>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <a @click="isForrbiden('allow',supplier)" style="width:100%">启用</a>
                                        </li>
                                        <li>
                                            <a @click="isForrbiden('drop',supplier)" style="width:100%">作废</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination
                name="pagination"
                component="pagination"
            ></Pagination>
        </div>
        <!-- 公司信息 -->
        <CSDialog
            class="tenant-detail-dialog"
            :dialog-visible="companyInfo.visible"
            dialog-width="766px"
            dialog-header-class="alert-bg"
            @onClose="companyInfo.visible = false"
            :dialog-show-confirm-btn="false"
            dialog-title="供应商信息"
            dialog-cancel-btn-text="关闭"
        >
            <template
                v-slot:dialog-content
            >
                <div style="max-height:750px;overflow-y:auto;font-size:24px;padding:30px">
                    <div class="preview-tenant">
                        <p class="field">
                            <span class="field-label">统一社会信用代码</span>
                            <span class="field-content">
                                {{companyInfo.companyDetail.creditCode}}
                            </span>
                        </p>
                        <p class="field">
                            <span class="field-label">公司名称</span>
                            <span class="field-content">
                                {{companyInfo.companyDetail.companyName}}
                            </span>
                        </p>
                        <p class="field">
                            <span class="field-label">类型</span>
                            <span class="field-content">
                                {{companyInfo.companyDetail.typeName}}
                            </span>
                        </p>
                        <p class="field">
                            <span class="field-label">地址</span>
                            <span class="field-content">
                                {{companyInfo.companyDetail.addr}}
                            </span>
                        </p>
                        <p class="field">
                            <span class="field-label">法定代表人</span>
                            <span class="field-content">
                                {{companyInfo.companyDetail.dockingPeople}}
                            </span>
                        </p>
                        <p class="field">
                            <span class="field-label">注册资本</span>
                            <span class="field-content">
                                {{companyInfo.companyDetail.registeredCapital}}{{companyInfo.companyDetail.currencyType === 1 ? '万人民币' : '万美元'}}
                            </span>
                        </p>
                        <p class="field">
                            <span class="field-label">成立日期</span>
                            <span class="field-content">
                                {{companyInfo.companyDetail.foundationDate}}
                            </span>
                        </p>
                        <p class="field">
                            <span class="field-label">营业期限</span>
                            <span class="field-content">
                                {{companyInfo.companyDetail.businessTimeStart}} 至 {{companyInfo.companyDetail.businessTimeEnd}}
                            </span>
                        </p>
                        <p class="field">
                            <span class="field-label">经营范围</span>
                            <span class="field-content">
                                {{companyInfo.companyDetail.businessScope}}
                            </span>
                        </p>
                    </div>
                </div>
            </template>
        </CSDialog>
        <!-- 附件 -->
        <CSDialog
			class="tenant-detail-dialog"
			:dialog-visible="fileInfo.visible"
			@onClose="fileInfo.visible = false"
			:dialog-show-confirm-btn="false"
			dialog-title="附件"
			dialog-cancel-btn-text="关闭"
		>
			<template
				v-slot:dialog-content
			>
				<table style="margin:30px" class="modal-inner-table">
					<thead>
						<tr style="height:54px;line-height:54px;">
							<th style="width:545px">文件名称</th>
							<th style="width:264px">操作</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(annex,index) in JSON.parse(fileInfo.fileDetail.annex)" :key="index" style="height:64px;line-height:64px">
							<td>{{annex}}</td>
							<td
								style="
									display:flex;
									justify-content:space-around;
								"
							>
								<span class="allow-click">查看</span>
								<span class="allow-click">下载</span>
							</td>
						</tr>
					</tbody>
				</table>
			</template>
		</CSDialog>
        <!-- 备注 -->
        <CSDialog
            dialog-width="540px"
			class="tenant-detail-dialog"
            style="text-align:center"
            dialog-header-class="alert-bg"
			:dialog-visible="noteInfo.visible"
			@onClose="noteInfo.visible = false"
			:dialog-show-confirm-btn="false"
			dialog-title="备注"
			dialog-cancel-btn-text="关闭"
		>
			<template>
				<div
					slot="dialog-content"
					style="padding: 20px 30px; font-size: 24px;"
				>
					<template>
						<div>
							{{noteInfo.noteDetail.comment}}
						</div>
					</template>
				</div>
			</template>
		</CSDialog>
        <!-- 添加供应商 -->
        <Supplier></Supplier>
        <!-- 是否启用/禁用/作废 -->
        <CSDialog
			class="tenant-detail-dialog"
            :dialog-visible="isBan"
            @onClose="isBan = false"
            @onConfirm="changeState()"
            dialog-width="430px"
			:dialog-title="forrbidenTxt.txt"
		>
			<template
                v-slot:dialog-content
            >
                <div style="font-size:24px;text-align:center;margin:30px;">确认{{forrbidenTxt.txt}}?</div>
            </template>
		</CSDialog>
        <ViewImageModal
            name="viewImageModal"
            componentName="ViewImageModal">
        </ViewImageModal>
    </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination";
import Supplier from "@/components/warehouse/supplier";
import ViewImageModal from "@/components/ViewImageModal";
import {STORAGE_KEY} from "@/constant";

import {
    warehouseSupplierUrl,
    warehouseSupplierStateUrl,
    warehouseSupplierDetailUrl,
    warehouseAddSupplierUrl,
}   from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

export default {
    components:{
        CSTable,
        CSSelect,
        CSDialog,
        Pagination,
        Supplier,
        ViewImageModal
    },
    data(){
        return {
            filterHeight: 0,
            isConfirm:false,
            isBan:false,
            tabBarType:2,
            newSupplier:{},
            // 启用 禁止 作废
            forrbidenTxt:{},
            supplierStateList:[
                {id:1,name:"启用"},
                {id:2,name:"禁用"},
                {id:3,name:"废除"}
            ],
            // 筛选查询
            searchTerm:{
                state:"",
                detail:"",
            },
            supplierInfo:[],
            // 公司类型
            companyTypeList:[
                {id:1,name:"有限责任公司"},
                {id:2,name:"股份有限公司"},
                {id:3,name:"股份合作公司"},
                {id:4,name:"国有企业"},
                {id:5,name:"集体所有制"},
                {id:6,name:"个体工商户"},
                {id:7,name:"独资企业"},
                {id:8,name:"有限合伙"},
                {id:9,name:"普通合伙"},
                {id:10,name:"外商投资企业"},
                {id:11,name:"港、澳、台商投资企业"},
                {id:12,name:"联营企业"},
                {id:13,name:"私营企业"},
            ],
            licenseInfo:{
                visible:false,
                licenseDetail:{}
            },
            companyInfo:{
                visible:false,
                companyDetail:{}
            },
            fileInfo:{
                visible:false,
                fileDetail:{}
            },
            noteInfo:{
                visible:false,
                noteDetail:{}
            },
        }
    },
    created(){
        window.addEventListener("keydown",this.getSupplierDown);
        this.$vc.on(this.$route.path, "supplier","addSupplierDone",(data)=>{
            this.newSupplier = data;
            this.addNewItem();
        })
        this.$fly.post(warehouseSupplierDetailUrl,{
            regionCode:this.$vc.getCurrentRegion().communityId,
            search:"",
            isEnable:true,
        })
        this.getSupplier();
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.getSupplierDown);
    },
    deactivated(){
         window.removeEventListener("keydown",this.getSupplierDown);
    },
    mounted(){
        this.$vc.on(this.$route.path, "pagination","page_event",currentPage=>{
            this.getSupplier(currentPage);
        })
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    methods:{
        getSupplierDown(e){
            if(e.keyCode == 13){
                this.getSupplier();
            }
        },
        checkLicense(target){
            if(target.businessLicence instanceof Array){
                this.$vc.emit(this.$route.path, "viewImageModal", "view", JSON.parse(target.businessLicence));
            }else{
                this.$vc.emit(this.$route.path, "viewImageModal", "view", [target.businessLicence]);
            }
        },
        getSupplier(pageNo = 1,pageSize = 10){
            this.$fly.post(warehouseSupplierUrl,{
                regionCode:this.$vc.getCurrentRegion().communityId,
                search:this.searchTerm.detail,
                state:this.searchTerm.state,
                pageNo,
                pageSize,
            })
            .then(res=>{
                this.supplierInfo = res.data.datas;
                this.supplierInfo.forEach(supplier=>{
                    this.supplierStateList.forEach(state=>{
                        if(supplier.state === state.id){
                            supplier.stateCode = state.name;
                        }
                    })
                    this.companyTypeList.forEach(type=>{
                        if(supplier.type === type.id){
                            supplier.typeName = type.name;
                        }
                    })
                })
                if(pageNo === 1){
                    this.$vc.emit(this.$route.path, "pagination","init",{
                        total:res.data.total || res.data.datas.length,
                        currentPage:pageNo,
                        pageSize,
                    })
                }
            })
        },
        companyInterface(target){
            this.companyInfo = {
                visible:true,
                companyDetail:target,
            }
        },
        itemQuantityInterface(target,tabBar){
            var id = target.id;
            this.$router.push({
                name:"file",
                params:{
                    id,
                    tabBar,
                    target,
                }
            })
        },
        getTargetInfo(id){
            var supplierList = this.supplierInfo;
            var targetSupplier;
            for(var i = 0;i<supplierList.length;i++){
                if(supplierList[i].id === id){
                    targetSupplier = supplierList[i];
                    break;
                }
            }
            return targetSupplier;
        },
        fileInterface(target,tabBar){
            var id = target.id;
            this.$router.push({
                name:"file",
                params:{
                    id,
                    tabBar,
                }
            })
        },
        noteInterface(target){
            this.noteInfo = {
                visible:true,
                noteDetail:target,
            }
        },
        addSuppiler(){
            this.$vc.emit(this.$route.path, "supplier","addSupplier",true)
        },
        addNewItem(){
            this.$vc.emit(this.$route.path, "supplier","addSupplierJuge",true)
            this.$CSDialog.warning({
                title:"提示",
                messageHtml:"<span>提交后将无法修改信息,确认提交吗?</span>",
                onConfirm:()=>{
                    this.$fly.post(warehouseAddSupplierUrl,{
                        regionCode: this.$vc.getCurrentRegion().communityId,
                        creditCode: this.newSupplier.code,
                        companyName: this.newSupplier.company,
                        type: +this.newSupplier.companyType,
                        addr: this.newSupplier.companyAddress,
                        legalRepresentative: this.newSupplier.companyLawer,
                        currencyType: +this.newSupplier.cashType,
                        registeredCapital: +this.newSupplier.companyValue,
                        foundationDate: this.newSupplier.companyBirthday,
                        businessTimeStart: this.newSupplier.companyStartDate,
                        businessTimeEnd: this.newSupplier.companyEndDate,
                        businessScope: this.newSupplier.companyRange,
                        businessLicence: this.newSupplier.licence,
                        dockingPeople: this.newSupplier.connectPer,
                        dockingPeoplePhone: this.newSupplier.connectPerPhone,
                        annex: JSON.stringify(this.newSupplier.companyFile),
                        comment: this.newSupplier.companyNote,
                    })
                    .then(res=>{
                        if(res.code !== 0){
                            return false;
                        }
                        this.$CSDialog.instance.closeDialog();
                        this.getSupplier();
                    })
                }
            })
        },
        isForrbiden(state,target){
            let id = target.id;
            if(state === 'forrbiden'){
                this.forrbidenTxt = {
                    id,
                    stateCode:2,
                    txt:"禁用"
                }
            }else if(state === 'allow'){
                this.forrbidenTxt = {
                    id,
                    stateCode:1,
                    txt:"启用"
                }
            }else if(state === 'drop'){
                this.forrbidenTxt = {
                    id,
                    stateCode:3,
                    txt:"作废"
                }
            }
            this.changeState();
        },
        changeState(){
            this.$CSDialog.warning({
                title:"提示",
                messageHtml:"<span>确定"+this.forrbidenTxt.txt+"吗?</span>",
                onConfirm:()=>{
                    this.$fly.post(warehouseSupplierStateUrl,{
                        regionCode:this.$vc.getCurrentRegion().communityId,
                        id:this.forrbidenTxt.id,
                        state:this.forrbidenTxt.stateCode,
                    })
                    .then(res=>{
                        if(res.code !== 0){
                            return;
                        }
                        this.getSupplier();
                        this.isBan = false;
                    })
                    this.$CSDialog.instance.closeDialog();
                }
            })
        }
    },
    destroyed(){
        sessionStorage.setItem(STORAGE_KEY.WAREHOUSE_TABID,0);
    }
}
</script>

<style lang="stylus">
.tenant-detail-dialog
  .el-dialog__header
    display none
  .preview-tenant
    color #000
    .field
      &-label
        width 200px
        display inline-block
        margin-right 40px
        text-align right
        vertical-align top
      &-content
        display inline-block
        max-width calc(100% - 240px)
  .el-divider__text.is-left
    font-size 24px
</style>
