<template>
    <div class="supplier-dialog">
        <CSDialog
            class="tenant-detail-dialog"
            dialog-width="1012px"
            :dialog-visible="newSupplierInfo"
            @onClose="newSupplierInfo=false"
            @onConfirm="jugementSupplier()"
            dialog-title="添加供应商"
        >
            <template
                v-slot:dialog-content
            >
                <div style="max-height:1341px;overflow-y:auto;font-size:24px;padding:30px">
                    <div class="preview-tenant">
                        <p class="field">
                            <span style="margin-top:1px;" class="field-label">统一社会信用代码</span>
                            <input style="
                                        width:380px;
                                        height:40px;
                                        padding:0px 10px;
                                        border:1px solid #979797;
                                        border-radius:4px;"
                                type="text" placeholder="限18位" v-model="addSupplierInfo.code"/>
                        </p>
                        <p class="field">
                            <span style="margin-top:1px;" class="field-label">公司名称</span>
                            <input style="
                                        width:380px;
                                        height:40px;
                                        padding:0px 10px;
                                        border:1px solid #979797;
                                        border-radius:4px;"
                                type="text" placeholder="限30个字" v-model="addSupplierInfo.company"/>
                        </p>
                        <p class="field">
                            <span style="margin-top:1px;" class="field-label">类型</span>
                            <CSSelect iWidth="36px" height="40px">
                                <select style="width:246px;padding:0px 5px;color: #999999" v-model="addSupplierInfo.companyType">
                                    <option selected value="">
                                        请选择
                                    </option>
                                    <option
                                        v-for="itemType in companyTypeList"
                                        :value="itemType.id"
                                        :key="itemType.id"
                                    >
                                    {{itemType.name}}
                                    </option>
                                </select>
                            </CSSelect>
                        </p>
                        <p class="field">
                            <span style="margin-top:1px;" class="field-label">地址</span>
                            <input style="
                                        width:380px;
                                        height:40px;
                                        padding:0px 10px;
                                        border:1px solid #979797;
                                        border-radius:4px;"
                                type="text" placeholder="限30个字" v-model="addSupplierInfo.companyAddress"/>
                        </p>
                        <p class="field">
                            <span style="margin-top:1px;" class="field-label">法定代表人</span>
                            <input style="
                                        width:380px;
                                        height:40px;
                                        padding:0px 10px;
                                        border:1px solid #979797;
                                        border-radius:4px;"
                                type="text" placeholder="限10个字" v-model="addSupplierInfo.companyLawer"/>
                        </p>
                        <p
                            style="
                                display:flex;
                                align-items:center;
                            "
                            class="field">
                                <span style="margin-top:1px;" class="field-label">注册资本</span>
                                <label style="vertical-align:middle;margin-right:15px;margin-bottom:0px;">
                                    <input v-model="addSupplierInfo.cashType" style="margin-right:10px;vertical-align:middle" type="radio" value="1" name="type" checked/>人民币
                                </label>
                                <label style="vertical-align:middle;margin-left:15px;margin-bottom:0px;">
                                    <input v-model="addSupplierInfo.cashType" style="margin-right:10px;vertical-align:middle" type="radio" value="2" name="type"/>美元
                                </label>
                                <input style="
                                            width:255px;
                                            height:40px;
                                            vertical-align:middle;
                                            padding:0px 10px;
                                            margin-left:30px;
                                            margin-right:0.5em;
                                            border:1px solid #979797;
                                            border-radius:4px;"
                                    type="text" placeholder="限整数" v-model="addSupplierInfo.companyValue"/>
                                {{addSupplierInfo.cashType == 1 ? '万人民币' : '万美元'}}
                        </p>
                        <p id="timeField" class="field">
                            <span style="margin-top:1px;" class="field-label">成立日期</span>
                            <CSSelect iWidth="36px" height="40px">
                                <el-date-picker
                                    width="220px"
                                    type="date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    placeholder="请选择"
                                    v-model="addSupplierInfo.companyBirthday"
                                    :editable="false"
                                ></el-date-picker>
                            </CSSelect>
                        </p>
                        <p id="timeField" class="field">
                            <span style="margin-top:1px;" class="field-label">营业期限</span>
                            <CSSelect iWidth="36px" height="40px">
                                <el-date-picker
                                    type="date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    placeholder="请选择"
                                    v-model="addSupplierInfo.companyStartDate"
                                    :editable="false"
                                ></el-date-picker>
                            </CSSelect>
                            <span style="margin:0px 10px;vertical-align:middle">至</span>
                            <CSSelect iWidth="36px" height="40px">
                                <el-date-picker
                                    type="date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    placeholder="请选择"
                                    v-model="addSupplierInfo.companyEndDate"
                                    :editable="false"
                                ></el-date-picker>
                            </CSSelect>
                        </p>
                        <p class="field">
                            <span style="margin-top:1px;" class="field-label">经营范围</span>
                            <textarea
                                style="
                                        width:580px;
                                        height:180px;
                                        padding:10px;
                                        resize:none;
                                        border:0px;
                                        border-radius:10px;
                                        background-color:#F0F0F0
                                       "
                                placeholder="限400个字" v-model="addSupplierInfo.companyRange"
                            ></textarea>
                        </p>
                        <div class="field">
                            <div class="field-label">营业执照</div>
                            <div v-show="!addSupplierInfo.licence" style="display:inline-block;margin-bottom:20px;">
                                <div class="upload-photo"
                                    @click="uploadImage()"
                                >
                                    <img
                                        src="../../assets/upload.png"
                                        alt=""
                                        style="margin:10px auto;"
                                    />
                                    <p style="color:#999999;">上传照片</p>
                                <input
                                    type="file"
                                    id="uploadFile"
                                    accept="image/*"
                                    hidden
                                    @change="showImage($event)"/>
                                </div>
                            </div>
                            <div
                                style="width:80px;height:80px;margin-bottom:20px;"
                                class="photo-view"
                                v-show="addSupplierInfo.licence"
                            >
                                <img v-show="addSupplierInfo.licence !== ''" :src="addSupplierInfo.licence" style="width:80px;height:80px"/>
                                <img
                                    @click="deleteLicence()"
                                    src="../../assets/icon4.png"
                                    style="
                                            width:15px;
                                            height:15px;
                                            position:absolute;
                                            top:-5px;
                                            right:-5px;
                                        "
                                    />
                            </div>
                            <input id="uploadImg" type="file" class="file" accept="image/*" hidden/>
                        </div>
                        <p class="field">
                            <span style="margin-top:1px;" class="field-label">对接人</span>
                            <input style="
                                        width:380px;
                                        height:40px;
                                        padding:0px 10px;
                                        border:1px solid #979797;
                                        border-radius:4px;"
                                type="text" placeholder="限10个字" v-model="addSupplierInfo.connectPer"/>
                        </p>
                        <p class="field">
                            <span style="margin-top:1px;" class="field-label">对接人手机号</span>
                            <input style="
                                        width:380px;
                                        height:40px;
                                        padding:0px 10px;
                                        border:1px solid #979797;
                                        border-radius:4px;"
                                type="text" placeholder="限11位数字" maxlength="11" v-model="addSupplierInfo.connectPerPhone"/>
                        </p>
                        <p class="field">
                            <span class="field-label">附件</span>
                            <span style="width:500px;display:inline-block">
                                <span style="vertical-align:top">
                                    <span
                                        v-show="JSON.stringify(addSupplierInfo.companyFile) !== '[]'"
                                        v-for="(item,index) in addSupplierInfo.companyFile" :key="index"
                                    >
                                        <span
                                            style="
                                                width:300px;
                                                text-overflow:ellipsis;
                                                overflow:hidden;
                                                white-space:nowrap;
                                                display:inline-block;
                                            "
                                        >{{item.slice(item.indexOf('/')+1)}}</span>
                                        <span
                                            style="vertical-align:top;"
                                            v-show="JSON.stringify(addSupplierInfo.companyFile) !== '[]'"
                                            @click="deleteFileList(index)"
                                            class="allow-click"
                                        >
                                            删除
                                        </span>
                                    </span>
                                </span>
                                <br v-show="JSON.stringify(addSupplierInfo.companyFile) !== '[]'"/>
                                <span
                                    style="vertical-align:super"
                                    class="allow-click"
                                    @click="uploadDoc()"
                                >
                                    上传
                                </span>
                            </span>
                            <input
                                type="file"
                                id="uploadDoc"
                                hidden
                                accept="image/*,.xls,.doc,.pdf"
                                @change="showDoc($event)"
                            />
                        </p>
                        <p>
                            <span class="field-label">备注</span>
                            <textarea
                                style="
                                        width:580px;
                                        height:180px;
                                        padding:10px;
                                        resize:none;
                                        border:0px;
                                        border-radius:10px;
                                        background-color:#F0F0F0
                                       "
                                       maxlength="200"
                                placeholder="限200个字，非必填"
                                v-model="addSupplierInfo.companyNote"
                            ></textarea>
                        </p>
                        <span style="
                                    margin-left:240px;
                                    font-size:20px;
                                    color:#999999;
                                    line-height:28px;
                        ">
                            <svg
                                class="icon"
                                aria-hidden="true"
                            >
                                <use xlink:href="#icon-menua-zu92"></use>
                            </svg>
                            确定添加后，将无法修改信息，请仔细认真检查所填信息。
                        </span>
                    </div>
                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";

export default {
    components:{
        CSDialog,
        CSSelect,
    },
    data(){
        return {
            newSupplierInfo:false,
            addSupplierInfo:{
                code:"",
                company:"",
                companyType:"",
                companyAddress:"",
                companyLawer:"",
                cashType:1,
                companyValue:"",
                companyBirthday:"",
                companyStartDate:"",
                companyEndDate:"",
                companyRange:"",
                connectPer:"",
                connectPerPhone:"",
                companyFile:[],
                companyNote:"",
                licence:"",
            },
            companyTypeList:[
                {id:1,name:"有限责任公司"},
                {id:2,name:"股份有限公司"},
                {id:3,name:"股份合作公司"},
                {id:4,name:"国有企业"},
                {id:5,name:"集体所有制"},
                {id:6,name:"个体工商户"},
                {id:7,name:"独资企业"},
                {id:8,name:"有限合伙"},
                {id:9,name:"普通合伙"},
                {id:10,name:"外商投资企业"},
                {id:11,name:"港、澳、台商投资企业"},
                {id:12,name:"联营企业"},
                {id:13,name:"私营企业"},
            ],
        }
    },
    created(){
        this.$vc.on(this.$route.path, "supplier","addSupplier",(data)=>{
            this.newSupplierInfo = data;
        })
        this.$vc.on(this.$route.path, "supplier","addSupplierJuge",(isDone)=>{
            if(isDone){
                this.addSupplierInfo = {
                    code:"",
                    company:"",
                    companyType:"",
                    companyAddress:"",
                    companyLawer:"",
                    cashType:1,
                    companyValue:"",
                    companyBirthday:"",
                    companyStartDate:"",
                    companyEndDate:"",
                    companyRange:"",
                    connectPer:"",
                    connectPerPhone:"",
                    companyFile:"",
                    companyNote:"",
                    companyFile:[],
                    licence:"",
                }
            }
        })
    },
    methods:{
        deleteLicence(){
            this.addSupplierInfo.licence = "";
        },
        deleteFileList(index){
            this.addSupplierInfo.companyFile.splice(index,1)
        },
        jugementSupplier(){
            if(this.jugement()){
                this.newSupplierInfo = false;
                this.$vc.emit(this.$route.path, "supplier","addSupplierDone",this.addSupplierInfo);
            }
        },
        jugement(){
            var startDate = this.addSupplierInfo.companyStartDate;
            var endDate = this.addSupplierInfo.companyEndDate;
            var birthDate = this.addSupplierInfo.companyBirthday;
            if(dayjs(startDate).isBefore(dayjs(birthDate))){
                this.$vc.toast("公司营业开始不能比公司成立时间早");
                return false;
            }else if(dayjs(endDate).isBefore(dayjs(startDate))){
                this.$vc.toast("公司营业结束时间不能比公司开始时间早");
                return false;
            }else{
                return this.$vc.validate.validate(
                    {
                        addSupplier:this.addSupplierInfo,
                    },
                    {
                        "addSupplier.code":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请输入社会信用代码",
                            },
                            {
                                limit:"maxin",
                                param:"1,18",
                                errInfo:"社会信用代码为0-18位",
                            }
                        ],
                        "addSupplier.company":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请输入公司名称"
                            },
                            {
                                limit:"maxin",
                                param:"0,30",
                                errInfo:"公司名称长度为0-30位",
                            }
                        ],
                        "addSupplier.companyType":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择公司类型",
                            }
                        ],
                        "addSupplier.companyAddress":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请输入公司地址",
                            },
                            {
                                limit:"maxin",
                                param:"0,30",
                                errInfo:"公司地址长度为0-30位",
                            }
                        ],
                        "addSupplier.companyLawer":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请输入公司法定代表人",
                            },
                            {
                                limit:"maxin",
                                param:"0,10",
                                errInfo:"公司法定代表人名长度为0-10位",
                            }
                        ],
                        "addSupplier.companyValue":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请输入公司注册成本",
                            },
                            {
                                limit:"num",
                                param:"",
                                errInfo:"请输入正确的数字格式"
                            }
                        ],
                        "addSupplier.companyBirthday":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择公司注册日期",
                            },
                        ],
                        "addSupplier.companyStartDate":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择公司运营开始时间",
                            },
                        ],
                        "addSupplier.companyEndDate":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择公司运营结束时间",
                            },
                        ],
                        "addSupplier.companyRange":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请输入公司经营范围"
                            }
                        ],
                        "addSupplier.licence":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请上传公司营业执照",
                            },
                        ],
                        "addSupplier.connectPer":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请输入公司对接人",
                            },
                            {
                                limit:"maxin",
                                param:"0,10",
                                errInfo:"公司对接人名称长度为0-10位",
                            }
                        ],
                        "addSupplier.connectPerPhone":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请输入公司对接人手机号",
                            },
                            {
                                limit:"num",
                                param:"",
                                errInfo:"请输入正确的手机号格式",
                            },
                            {
                                limit:"phone",
                                param:"",
                                errInfo:"请输入0-11位手机号"
                            }
                        ]
                    }
                )
            }
        },
        uploadImage(){
            $("#uploadFile").trigger("click");
        },
        async uploadPhoto(base64) {
            return new Promise((reslove, reject) => {
                this.$vc.http.post(
                "uploadImg",
                "upload",
                {
                    img: base64,
                },
                {
                    headres: {
                    "Content-Type": "application/json",
                    },
                },
                (resStr, res) => {
                    reslove(res.body.fileSaveName);
                },
                (errText, err) => {
                    reject(errText);
                }
                );
            });
        },
        showImage(event){
            const photoFiles = event.target.files;
            if(photoFiles && photoFiles.length > 0){
                const photo = photoFiles[0];
                if(photo.size > 1024 * 1024 * 2){
                    this.$vc.toast("图片大小不能超过 2MB!")
                    return false;
                }
                this.$fly.putFile(photo).then(res => {
                    this.addSupplierInfo.licence = res.url;
                });
            }
        },
        uploadDoc(){
            $("#uploadDoc").trigger("click");
        },
        showDoc(event){
            const files = event.target.files;
            if(files && files.length > 0){
                const file = files[0];
                this.$fly.putFile(file).then(res => {
                    this.addSupplierInfo.companyFile.push(res.name);
                });
            }
        }
    }
}
</script>

<style scoped>
/deep/.el-input__inner {
    font-size: 24px;
}
/deep/.el-input__inner::placeholder {
    color: #999;
}
</style>
